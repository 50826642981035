<template>
	<v-dialog class="chat-haggle" v-model="is_show" persistent width="auto" fullscreen eagar transition="slide-fade" hide-overlay light>
		<v-row no-gutters style="background: rgba(0, 0, 0, 0.3)">
			<v-col cols="12" lg="9" md="7" sm="6"></v-col>
			<v-col cols="11" lg="3" md="5" sm="6" style="height: 100vh; background: #ffffff; margin-left: auto" class="bid-box pa-0 d-flex flex-column">
				<div class="" style="position: relative; display: flex; flex-direction: column; max-height: 100vh; height: 100vh">
					<v-row no-gutters class="px-6 d-sm-flex align-center justify-space-between" style="max-height: 79px; border-bottom: 1px solid #e7eaf3">
						<div class="d-flex align-center">
							<div @click="openUserChat()" class="d-flex align-center justify-center back-arrow">
								<img :src="$store.state.icons.icons['LeftArrow']" alt />
							</div>

							<div v-if="user" class="px-2 user-profile d-flex align-center">
								<div class="d-flex align-center justify-center">
									<img v-if="user.profile_link" :src="user.profile_link" alt="" />
									<v-avatar v-else-if="user.fullname" color="#2974ff" size="40" class="avtar-text">
										{{
											user.fullname
												.split(' ')
												.map((x) => x[0].toUpperCase())
												.join('')
										}}
									</v-avatar>
								</div>

								<div class="pl-4">
									<div class="user-name">
										{{ user.fullname }}
									</div>
									<div v-if="user.isUserOnline" class="active-status">Active now</div>
								</div>
							</div>
						</div>

						<!-- <div>
							<v-menu v-model="menuDialog" offset-y bottom left :nudge-left="4" :nudge-bottom="6">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
								</template>
								<v-list>
									<v-dialog
										content-class="email"
										v-model="dialog"
										persistent
										width="300"
										attach=".user-profile"
										transition="slide-fade"
										hide-overlay
										light
									>
										<template v-slot:activator="{ on, attrs }">
											<div class="px-3 cursor-pointer font-weight-bold" style="font-size: 14px" v-bind="attrs" v-on="on">Close Chat</div>
										</template>

										<v-card>
											<v-card-text> <div class="pt-5 text-center">Do you need copy of your conversation on email?</div> </v-card-text>

											<v-card-actions class="justify-space-around mx-10">
												<div class="cursor-pointer" plain color="#000" @click=";(dialog = false), (menuDialog = false)">No</div>

												<div class="cursor-pointer" plain color="#000" @click="closeChat">Yes</div>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-list>
							</v-menu>
						</div> -->
					</v-row>

					<v-row no-gutters class="pl-6 chats-users chat">
						<p class="nomessages" v-if="messages.length == 0">No messages yet!</p>
						<div
							v-else
							class="messages pr-4 chat"
							v-chat-scroll="{
								always: false,
								smooth: true,
								notSmoothOnInit: true,
								scrollonremoved: false,
							}"
							@v-chat-scroll-top-reached="customMethod"
						>
							<div v-for="(messageGroup, index) in messagesByGroup" :key="index">
								<div class="text-center pt-3 pb-1">
									<span class="group-lable" v-if="todayDate === index"> {{ 'Today' }}, {{ moment(messageGroup[0].time).format('LT') }} </span>
									<span class="group-lable" v-else-if="yesterdayDate === index">
										{{ 'Yesterday' }}, {{ moment(messageGroup[0].time).format('LT') }}
									</span>
									<span class="group-lable" v-else
										>{{ moment(index, 'MM-DD-YYYY').format('MMM, DD YYYY') }}, {{ moment(messageGroup[0].time).format('LT') }}</span
									>
								</div>
								<div
									class="message"
									v-for="message in messageGroup"
									:id="message.id"
									:key="message.id"
									:class="{ 'ml-auto mr-1': message.sendBy == `${$store.state.auth.user_data.company_id}DC` }"
								>
									<div
										v-if="message.message"
										:class="{
											'ml-auto sender-message': message.sendBy == `${$store.state.auth.user_data.company_id}DC`,
											'img-msg': message.docType.toLowerCase() == 'image',
											msg: message.docType.toLowerCase() != 'object',
										}"
									>
										<span v-if="message.docType.toLowerCase() == 'text'" class="text">{{ message.message }} </span>

										<div v-if="message.docType.toLowerCase() == 'file' && message.docUrl">
											<a :href="message.docUrl" target="_blank">
												<div
													class="pdf-block d-flex align-center"
													:class="{ 'text--white': message.sendBy == `${$store.state.auth.user_data.company_id}DC` }"
												>
													<img class="mr-4" src="../../src/assets/images/pdf-icon.png" alt="" srcset="" width="35" />
													<div>
														<div class="pdf-name">{{ message.docName }}</div>
														<div class="pdf-size">{{ message.docSize }}</div>
													</div>
												</div>
											</a>
										</div>

										<div v-if="message.docType.toLowerCase() == 'image' && message.docUrl">
											<div v-if="isLoading" class="loading"></div>
											<div v-show="isLoaded" class="image-block">
												<a
													:href="message.docUrl"
													target="_blank"
													:class="{
														'sender-message': message.sendBy == `${$store.state.auth.user_data.company_id}DC`,
													}"
												>
													<img :src="message.docUrl" alt="" srcset="" @load="loaded" />
												</a>
											</div>
										</div>

										<!-- <span
											v-if="message.docType.toLowerCase() != 'object' && message.docType.toLowerCase() != 'acceptrejectlable'"
											class="time mt-2 mb-3"
											:class="{
												'text-right': message.sendBy == `${$store.state.auth.user_data.company_id}DC`,
												'img-time': message.docType.toLowerCase() == 'image',
											}"
										>
											{{ moment(message.time).format('LT') }}
										</span> -->
									</div>
									<quote
										v-if="message.docType.toLowerCase() == 'object' && message.bidobj"
										:sender="message.sendBy"
										:bidobj.sync="message.bidobj"
										:id="message.id"
										:userName="user.fullname"
										@displayChangeBid="displayChangeBid"
									/>

									<div class="accepted-msg mb-4" v-if="message.acceptRejectLable">
										<div class="d-flex align-center justify-center" v-if="message.acceptRejectLable == 'Yes'">
											<icon-right />
											<span> {{ user.fullname }} accepted new price</span>
										</div>
										<div class="d-flex align-center justify-center" v-if="message.acceptRejectLable == 'No'">
											<icon-close />
											<span class="ml-2"> {{ user.fullname }} Rejected new price</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- <v-col v-for="(user, index) in users" :key="index" cols="12" class="pa-0 px-4">
							<div class="px-4 user-list d-flex align-center">
								<div class="d-flex align-center justify-center">
									<img :src="require('../assets/images/' + user.id + '.png')" alt="" />
								</div>

								<div class="pl-4">
									<div class="user-name d-flex align-center">
										<div class="dot-icon mr-3"></div>
										{{ user.name }}
									</div>
									<div class="last-message">
										{{ user.lastMessage }}
									</div>
								</div>
							</div>
						</v-col> -->
					</v-row>
					<div :class="{ 'block-message': isUserBlocked }">
						<p v-if="isUserBlocked">You are no longer allowed to send message!</p>
						<create-chat-message v-else ref="createMessage"></create-chat-message>
					</div>
					<!-- </v-card> -->
				</div>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import moment from 'moment'

import Vue from 'vue'
import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../config/variable'

const CreateChatMessage = () => import('../mycomponents/CreateChatMessage.vue')
const quote = () => import('./ChatChangeQuote.vue')
const iconRight = () => import('./icon-right.vue')
const iconClose = () => import('./icon-close.vue')

export default {
	props: {
		is_show: {
			type: Boolean,
			default: false,
		},
		isUserBlocked: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		VueChatScroll,
		CreateChatMessage,
		quote,
		iconRight,
		iconClose,
	},
	data() {
		return {
			isFeachingNewChats: false,
			messages: [],
			lastReference: {},
			lastObjId: '',
			loading: false,
			dialog: false,
			menuDialog: false,
			isLoaded: false,
			isLoading: false,
			// imgsrc: '',
			// overlay: true,
			isMounted: false,
			// user: {},
		}
	},

	async created() {
		this.moment = moment
		this.todayDate = moment().format('MM-DD-YYYY')
		this.yesterdayDate = moment().subtract(1, 'days').format('MM-DD-YYYY')

		if (this.$store.state.chathaggle.collection_id) {
			await this.getUserMessages(this.$store.state.chathaggle.collection_id)
		}

		this.todayDate = moment().format('MM-DD-YYYY')
		this.yesterdayDate = moment().subtract(1, 'days').format('MM-DD-YYYY')

		let domRect = document.getElementById('#' + this.lastMessageId)
		if (domRect !== null) {
			domRect = document.getElementById('#' + this.lastMessageId).getBoundingClientRect()
			window.scrollTo({
				top: domRect.top + document.documentElement.scrollTop - 35,
				behavior: 'smooth',
			})
		}
	},

	async mounted() {
		this.toggleimg()
	},

	computed: {
		overlay() {
			if (this.$refs.createMessage) {
				return this.$refs.createMessage.loading
			}
		},

		...mapGetters({
			user: 'chatUser',
			// messages: 'getMessages',
		}),

		messagesByGroup() {
			let messages = this.messages.sort((a, b) => (a.time > b.time ? 1 : -1))
			const groupBy = (array, key) => {
				return array.reduce((result, currentValue) => {
					// eslint-disable-next-line no-extra-semi
					;(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
					return result
				}, {})
			}

			return groupBy(messages, 'date')
		},
	},

	methods: {
		loaded() {
			this.isLoaded = true
			this.isLoading = false
		},

		toggleimg() {
			this.isLoaded = false
			this.isLoading = true
		},

		async customMethod() {
			if (this.isFeachingNewChats === false) {
				this.isFeachingNewChats = true
				if (this.lastReference !== '' && this.lastReference !== undefined) {
					let ref = firebase.firestore().collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')

					ref = await ref
						.doc(this.$store.state.chathaggle.collection_id)
						.collection('chats')
						.orderBy('time', 'desc')
						.limit(this.messages.length + 20)
						.get()

					const lastReference = ref.docs[ref.docs.length - 1]
					this.lastReference = lastReference

					ref.docs.forEach((doc) => {
						let message = {
							id: doc.id,
							...doc.data(),
							date: moment(doc.data().time).format('MM-DD-YYYY'),
						}

						this.pushUserMessages(message)
					})
				}

				this.isFeachingNewChats = false
			}
		},

		async getUserMessages(listDistCompanyId) {
			this.loading = true

			let ref = firebase.firestore().collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
			ref = await ref.doc(listDistCompanyId).collection('chats').orderBy('time', 'desc').limit(30)
			ref.onSnapshot((doc) => {
				this.messages = []
				const lastReference = doc.docs[doc.docs.length - 1]
				this.lastReference = lastReference

				doc.forEach((doc) => {
					let message = {
						id: doc.id,
						...doc.data(),
						date: moment(doc.data().time).format('MM-DD-YYYY'),
					}
					this.pushUserMessages(message, 'first')
				})
			})
			this.loading = false
		},

		pushUserMessages(message, type) {
			if (type == 'first') {
				this.messages.push(message)
			} else {
				if (!this.userMessageExists(message.id)) {
					this.messages.push(message)
				}
			}
		},

		userMessageExists(id) {
			return this.messages.some(function (el) {
				return el.id === id
			})
		},

		resetUserMessages() {
			this.messages = []
		},

		// async closeChat() {
		// 	let ref = await firebase
		// 		.firestore()
		// 		.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
		// 		.doc(this.$store.state.chathaggle.collection_id)
		// 		.get()
		// 	let chat = await ref.data()
		// 	if (chat.is_active_chat) {
		// 		await AXIOS.post('chat-close', this.$store.state.chathaggle.collection_id)
		// 		;(this.menuDialog = false), (this.dialog = false), this.$emit('openUserChat')
		// 		await firebase
		// 			.firestore()
		// 			.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
		// 			.doc(this.$store.state.chathaggle.collection_id)
		// 			.update({ is_active_chat: false })
		// 	}
		// },

		showChatIcon() {
			this.$emit('showChatIcon')
		},

		openUserChat() {
			this.$emit('openUserChat')
		},

		displayChangeBid(data) {
			this.$emit('displayChangeBid', data)
		},
	},
}
</script>

<style lang="scss" scoped>
.chat-search-box .v-input__slot {
	background: #e0e8ed !important;
	border: none !important;
	border-radius: 4px !important;
	padding-left: 15px;
	min-height: 40px !important;
}

.back-arrow {
	cursor: pointer;
	background: #2974ff1a;
	mix-blend-mode: normal;
	border-radius: 4px;
	width: 30px;
	height: 30px;
	img {
		height: 12px;
	}
}
.chat-search-box .v-input__control > .v-input__slot:focus-within {
	background: #e0e8ed !important;
}

.chat-search-box .v-input__control > .v-input__slot {
	box-shadow: none !important;
}

.chat-main-h1 {
	font-family: 'Inter-Medium';
	font-size: 24px;
}

.user-profile {
	img {
		height: 32px !important;
		width: 32px !important;
		border-radius: 50%;
		object-fit: cover;
	}

	.dot-icon {
		content: '';
		height: 10px;
		width: 10px;
		border-radius: 10px;
		background: #2974ff;
	}

	.user-name {
		color: #212121;
		font-family: 'Inter-Regular';
		font-size: 15px;
		font-weight: 700;
	}

	.active-status {
		display: block;
		color: #7c8da6;
		font-family: 'Inter-Medium';

		font-family: 'Inter-Regular';
		font-size: 12px;
		line-height: 12px;
	}
}
.chats-users {
	max-height: calc(100vh - 159px);
	overflow-y: scroll;
}

.chat span {
	font-size: 13px;
	border-radius: 6px;
}
.chat .time {
	// display: block;
	font-size: 10px;
	padding-left: 8px;
	margin-bottom: 0.2rem !important;
	margin-top: auto !important;
	width: fit-content;
	white-space: nowrap;

	&.img-time {
		position: absolute;
		right: 9px;
		bottom: 2px;
	}
}
.messages {
	max-height: 81vh;
	width: 100%;
	overflow: auto;
	height: 100%;
	padding-top: 10px;
	padding-bottom: 3px;

	&::-webkit-scrollbar {
		width: 3px !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c0bebe;
	}
}
.message {
	margin-top: 6px;
}
.msg {
	padding: 2px 20px;
	background: #ebeef6;
	color: #343434;
	// margin-bottom: 0.2rem;
	display: flex;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 16px 16px 16px 0px;
	font-size: 13px;
	max-width: 300px;

	a {
		text-decoration: none;
	}

	&.img-msg {
		padding: 0;
		display: block;
		position: relative;
		background: #ebeef6 !important;
	}
}
.sender-message {
	border-radius: 16px 16px 0px 16px;
	background: #2974ff;
	font-size: 13px;
	color: #ffffff;
}
.msg > .text {
	padding-top: 0.4rem;
	display: block;
	padding-bottom: 0.4rem;
	white-space: pre-line;
	word-break: break-all;
}

.group-lable {
	color: #7c8da6;
	padding: 0.5rem 0.8rem;
	font-size: 13px !important;
	font-family: 'Inter-Medium' !important;
}
.menu-options {
	cursor: pointer;
}

.nomessages {
	color: rgb(200, 196, 196) !important;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}

.pdf-block {
	border-radius: 6px;
	padding: 10px;
	color: #343434;

	.pdf-name {
		font-family: 'Inter-Medium';
		word-break: break-all;
	}
}

.text--white {
	color: #fff;
}

.image-block {
	a {
		display: block;
		object-fit: cover;
		border-radius: 16px 16px 16px 0px;
		max-width: 300px;
		overflow: hidden;
		min-height: 100px;
		min-width: 100px;

		&.sender-message {
			border-radius: 15px 15px 0;
		}
	}
	img {
		height: 250px;
		width: 250px;
		display: block;
		object-fit: cover;
		background: #ebeef6 !important;
	}
}

.accepted-msg {
	font-size: 15px;
	line-height: 18px;
	color: #414141;
	font-family: 'Inter-Light';
}
.loading {
	background: transparent url('../../src/assets/loader.gif') center no-repeat;
	background-size: 32px 32px;
	height: 250px;
	width: 250px;
}

.block-message {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 2px 2px #eee inset;

	p {
		margin-bottom: 0;
		font-family: 'Inter-Medium';
		color: #212121;
		font-size: 14px;
	}
}
</style>
